<template>
  <div id="moneyHistogramId" style="width: 100%; height: 700px"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'MoneyHistogram',
  props: {
    companyMoneyList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      yearList: [],
    }
  },
  computed: {},

  watch: {
    companyMoneyList: {
      deep: true,
      handler: function (list) {
        if (list.length > 0) {
          this.init(list)
        } else {
          this.init([])
        }
      },
    },
  },
  async created() {
    this.$api.dict
      .listSysDictData('STATISTICS_YEAR', true)
      .then(async r => {
        this.yearList = r.data
        const res = await this.$api.statistics.queryCompanyRevenueExpenseTrend(
          `date=${this.yearList[0].dictVal}`
        )

        const { companyExpenseResponseVo = {} } = res.data

        const nameMapping = {
          actualReceiptsList: '收款',
          actualExpenditureList: '支出',
          fundBalanceList: '资金余额',
        }
        const colorArr = ['#0064ff', '#ff6c60', '#f8d347']
        const arr = Object.keys(companyExpenseResponseVo)
          .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
          .map((key, index) => ({
            name: nameMapping[key], // 根据键获取名称
            data: companyExpenseResponseVo[key],
            type: 'bar',
            barMinHeight: 1.5, // 最小高度
            barGap: '0%', // 中间不间隔
            itemStyle: {
              // 设置柱子颜色
              color: colorArr[index],
            },
          }))
        this.init(arr)
      })
      .catch(err => {
        console.log(err)
      })
  },
  mounted() {},
  methods: {
    // 公司收支趋势
    init(arr = []) {
      function truncateToOneDecimal(num) {
        // 将数字转换为字符串，找到小数点的位置
        let str = num.toString()
        let dotIndex = str.indexOf('.')
        // 如果没有小数点，直接返回字符串
        if (dotIndex === -1) {
          return str
        }
        // 截取小数点后一位
        return str.slice(0, dotIndex + 2) // +2 是包括小数点和一位数字
      }
      this.$nextTick(() => {
        var chartDom = document.getElementById('moneyHistogramId')
        var myChart = echarts.init(chartDom)
        var option
        const nameArr = arr.map(v => v.name)
        const dataArr = arr.map(v => v.data)
        const tooltipArr = JSON.parse(JSON.stringify(dataArr))
        option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
            formatter: function (params) {
              const currentMonth = Number(params[0].name) + 1 + '月'
              let tooltipContent = currentMonth + '<br/>'
              const hasPositiveValue = params.some(item => item.value && item.value > 0)
              // 如果存在数据
              if (hasPositiveValue) {
                params.forEach(item => {
                  if (item.value !== null && item.value > 0) {
                    if (item.axisIndex == 0) {
                      tooltipContent += `${item.seriesName}: ${item.value}元<br>` // 拼接显示内容
                    } else if (item.axisIndex == 1) {
                      tooltipContent += `${item.seriesName}: -${item.value}元<br>` // 拼接显示内容
                    }
                  }
                })
              } else {
                tooltipContent += '无数据' // 如果没有数据，则返回 '无数据'
              }
              return tooltipContent
            },
          },
          legend: {
            data: nameArr,
          },
          grid: [
            {
              right: 20,
              left: 80,
              top: 30,
              bottom: '50%',
              containLabel: false,
            },
            {
              right: 20,
              left: 80,
              top: '50%',
              bottom: 30,
              containLabel: false,
              show: false,
            },
            {
              right: 20,
              left: 80,
              top: '50%',
              bottom: 30,
              containLabel: false,
              show: false,
            },
          ],
          xAxis: [
            {
              type: 'category',
              gridIndex: 0,
              show: false,
            },
            {
              type: 'category',
              show: false,
              gridIndex: 1,
            },
            {
              type: 'category',
              interval: 0,
              gridIndex: 2,
              data: [
                '1月',
                '2月',
                '3月',
                '4月',
                '5月',
                '6月',
                '7月',
                '8月',
                '9月',
                '10月',
                '11月',
                '12月',
              ],
            },
          ],
          yAxis: [
            {
              type: 'log',
              gridIndex: 0,
              min: 1, // 设置y轴最小值
              axisLabel: {
                formatter: val => {
                  if (val === 1) {
                    return 0
                  } else if (val >= 100000000) {
                    return (val / 100000000).toFixed(1) + '亿' // 大于等于1亿
                  } else if (val >= 10000) {
                    return (val / 10000).toFixed(1) + 'W' // 大于等于1万
                  }
                  return val // 其他情况下直接返回原数值
                },
              },
            },
            {
              type: 'log',
              gridIndex: 1,
              inverse: true, //设置反向坐标，让Y轴绕X轴上下翻转
              min: 1, // 设置y轴最小值
              axisLabel: {
                formatter: val => {
                  if (val === 1) {
                    return 0
                  } else if (val >= 100000000) {
                    return '-' + (val / 100000000).toFixed(1) + '亿' // 大于等于1亿
                  } else if (val >= 10000) {
                    return '-' + (val / 10000).toFixed(1) + 'W' // 大于等于1万
                  }
                  return `-${val}` // 其他情况下直接返回原数值
                },
              },
            },
            {
              type: 'value',
              gridIndex: 2,
              show: false,
            },
          ],
          series: [
            {
              name: nameArr[0],
              xAxisIndex: 0,
              yAxisIndex: 0,
              barWidth: '30%',
              barGap: '0%', // 中间不间隔
              type: 'bar',
              color: '#0064ff', // 标签颜色
              data: dataArr[0].map(item => (item > 0 ? (item > 1 ? item : 1.01) : null)), // 正数柱状图series.data只展示大于0的数据
              barMinHeight: 1.5, // 最小高度
              label: {
                show: true,
                position: 'top', // 标签位置
                formatter: function (params) {
                  const value = params.value // 获取当前柱子的数值
                  if (value >= 100000000) {
                    return truncateToOneDecimal(value / 100000000) + '亿' // 大于等于1亿
                  } else if (value >= 10000) {
                    return truncateToOneDecimal(value / 10000) + 'W' // 大于等于1万
                  } else if (value >= 1000) {
                    return truncateToOneDecimal(value / 1000) + 'K' // 大于等于1万
                  }
                  return value // 其他情况下直接返回原数值
                },
                fontSize: 12,
                fontWeight: 'bold',
                color: '#0064ff', // 标签颜色
              },
            },
            {
              name: nameArr[0],
              type: 'bar',
              xAxisIndex: 1,
              yAxisIndex: 1,
              barWidth: '30%',
              barGap: '0%', // 中间不间隔
              color: '#0064ff', // 标签颜色
              data: dataArr[0].map(item =>
                item < 0 ? (Math.abs(item) > 1 ? Math.abs(item) : 1.01) : null
              ), // 负数柱状图eries.data只展示小于0的数据
              barMinHeight: 1.5, // 最小高度
              label: {
                show: true,
                position: 'bottom', // 标签位置

                formatter: function (params) {
                  const value = params.value // 获取当前柱子的数值
                  if (value >= 100000000) {
                    return '-' + truncateToOneDecimal(value / 100000000) + '亿' // 大于等于1亿
                  } else if (value >= 10000) {
                    return '-' + truncateToOneDecimal(value / 10000) + 'W' // 大于等于1万
                  } else if (value >= 1000) {
                    return '-' + truncateToOneDecimal(value / 1000) + 'K' // 大于等于1万
                  }
                  return `-${value}` // 其他情况下直接返回原数值
                },
                fontSize: 12,
                fontWeight: 'bold',
                color: '#0064ff', // 标签颜色
              },
            },
            {
              name: nameArr[0],
              xAxisIndex: 2,
              yAxisIndex: 2,
              type: 'bar',
              show: false,
              data: dataArr[0].map(item => null),
            },
            {
              name: nameArr[1],
              xAxisIndex: 0,
              yAxisIndex: 0,
              barWidth: '30%',
              type: 'bar',
              barGap: '0%', // 中间不间隔
              color: '#ff6c60', // 标签颜色
              data: dataArr[1].map(item => (item > 0 ? (item > 1 ? item : 1.01) : null)), // 正数柱状图series.data只展示大于0的数据
              barMinHeight: 1.5, // 最小高度
              label: {
                show: true,
                position: 'top', // 标签位置
                formatter: function (params) {
                  const value = params.value // 获取当前柱子的数值
                  if (value >= 100000000) {
                    return truncateToOneDecimal(value / 100000000) + '亿' // 大于等于1亿
                  } else if (value >= 10000) {
                    return truncateToOneDecimal(value / 10000) + 'W' // 大于等于1万
                  } else if (value >= 1000) {
                    return truncateToOneDecimal(value / 1000) + 'K' // 大于等于1万
                  }
                  return value // 其他情况下直接返回原数值
                },
                fontSize: 12,
                fontWeight: 'bold',
                color: '#ff6c60', // 标签颜色
              },
            },
            {
              name: nameArr[1],
              type: 'bar',
              xAxisIndex: 1,
              yAxisIndex: 1,
              barWidth: '30%',
              barGap: '0%', // 中间不间隔
              color: '#FF4C4C', // 标签颜色
              data: dataArr[1].map(item =>
                item < 0 ? (Math.abs(item) > 1 ? Math.abs(item) : 1.01) : null
              ), // 负数柱状图eries.data只展示小于0的数据
              barMinHeight: 1.5, // 最小高度
              label: {
                show: true,
                position: 'bottom', // 标签位置

                formatter: function (params) {
                  const value = params.value // 获取当前柱子的数值
                  if (value >= 100000000) {
                    return '-' + truncateToOneDecimal(value / 100000000) + '亿' // 大于等于1亿
                  } else if (value >= 10000) {
                    return '-' + truncateToOneDecimal(value / 10000) + 'W' // 大于等于1万
                  } else if (value >= 1000) {
                    return '-' + truncateToOneDecimal(value / 1000) + 'K' // 大于等于1万
                  }
                  return `-${value}` // 其他情况下直接返回原数值
                },
                fontSize: 12,
                fontWeight: 'bold',
                color: '#ff6c60', // 标签颜色
              },
            },
            {
              name: nameArr[1],
              xAxisIndex: 2,
              yAxisIndex: 2,
              type: 'bar',
              show: false,
              data: dataArr[1].map(item => null),
            },
            {
              name: nameArr[2],
              xAxisIndex: 0,
              yAxisIndex: 0,
              barWidth: '30%',
              type: 'bar',
              barGap: '0%', // 中间不间隔
              color: '#f8d347', // 标签颜色
              data: dataArr[2].map(item => (item > 0 ? (item > 1 ? item : 1.01) : null)), // 正数柱状图series.data只展示大于0的数据
              barMinHeight: 1.5, // 最小高度
              label: {
                show: true,
                position: 'top', // 标签位置
                formatter: function (params) {
                  const value = params.value // 获取当前柱子的数值
                  if (value >= 100000000) {
                    return truncateToOneDecimal(value / 100000000) + '亿' // 大于等于1亿
                  } else if (value >= 10000) {
                    return truncateToOneDecimal(value / 10000) + 'W' // 大于等于1万
                  } else if (value >= 1000) {
                    return truncateToOneDecimal(value / 1000) + 'K' // 大于等于1万
                  }
                  return value // 其他情况下直接返回原数值
                },
                fontSize: 12,
                fontWeight: 'bold',
                color: '#f8d347', // 标签颜色
              },
            },
            {
              name: nameArr[2],
              type: 'bar',
              xAxisIndex: 1,
              yAxisIndex: 1,
              barWidth: '30%',
              barGap: '0%', // 中间不间隔
              color: '#f8d347', // 标签颜色
              data: dataArr[2].map(item =>
                item < 0 ? (Math.abs(item) > 1 ? Math.abs(item) : 1.01) : null
              ), // 负数柱状图eries.data只展示小于0的数据
              barMinHeight: 1.5, // 最小高度
              label: {
                show: true,
                position: 'bottom', // 标签位置

                formatter: function (params) {
                  const value = params.value // 获取当前柱子的数值
                  if (value >= 100000000) {
                    return '-' + truncateToOneDecimal(value / 100000000) + '亿' // 大于等于1亿
                  } else if (value >= 10000) {
                    return '-' + truncateToOneDecimal(value / 10000) + 'W' // 大于等于1万
                  } else if (value >= 1000) {
                    return '-' + truncateToOneDecimal(value / 1000) + 'K' // 大于等于1万
                  }
                  return `-${value}` // 其他情况下直接返回原数值
                },
                fontSize: 12,
                fontWeight: 'bold',
                color: '#f8d347', // 标签颜色
              },
            },
            {
              name: nameArr[2],
              xAxisIndex: 2,
              yAxisIndex: 2,
              type: 'bar',
              show: false,
              data: dataArr[2].map(item => null),
            },

            {
              // name: nameArr[2],
              // xAxisIndex: 0,
              yAxisIndex: 0,
              type: 'line',
              smooth: true, // 平滑折线
              // connectNulls:true,
              show: true,
              // data: dataArr[2].map((item) =>
              //   item > 0 ? (item > 1 ? item : 1.01) : null
              // )
              data: dataArr[2].map(value => (value <= 0 ? 1 : value)),
            },
            {
              // name: nameArr[2],
              xAxisIndex: 1,
              yAxisIndex: 1,
              smooth: true, // 平滑折线
              // connectNulls:true,
              type: 'line',
              show: true,
              // data: dataArr[2].map((value) => (value <= 0 ? value : 1))
              data: dataArr[2].map(item =>
                item < 0 ? (Math.abs(item) > 1 ? Math.abs(item) : 1.01) : 1
              ),
            },
          ],
        }
        option && myChart.setOption(option)
        myChart.setOption(option, true) // 第二个参数设置为 true，表示完全更新配置项
        //监听窗口变化
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      })
    },
  },
}
</script>
<style scoped></style>
